import { uiActions } from "../slices/ui";
import { markupsActions } from "../slices/markups";

import { sendRequest } from "./request";

export const getMarkups = () => async (dispatch) => {
  let result = [];
  try {
    result = await dispatch(sendRequest("markups", {}, "GET"));

    dispatch(markupsActions.setMarkups(result));
  } catch (err) {
    // console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve(result);
};

export const setMarkup = (markups) => async (dispatch) => {
  try {
    await dispatch(sendRequest("markups", { markups }));

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Success!",
        message: "Stawki zaktualizowane",
      })
    );
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve();
};
