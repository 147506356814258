import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Checkbox,
  Button,
  Pagination,
} from "@mui/material";

import { getBlacklist, removeFromBlacklist } from "../../store/actions/blacklist";
import { authActions } from "../../store/slices/auth";
import classes from "./BlacklistContent.module.css";

function InsertTab({ manufacturer }) {
  const dispatch = useDispatch();
  const blacklist = useSelector((state) => state.blacklist[manufacturer].docs);
  const totalPages = useSelector((state) => state.blacklist[manufacturer].totalPages);
  const perPage = useSelector((state) => state.blacklist[manufacturer].limit);
  const forceReload = useSelector((state) => state.auth.forceReload);

  const [blacklistArray, setBlacklistArray] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const loadData = async (force, page) => {
    if (force || (blacklist && Object.keys(blacklist).length <= 0)) {
      setIsLoading(true);
      await dispatch(getBlacklist(manufacturer, page ? page : currentPage));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData(false);
    loadData(true, 1);
  }, [manufacturer]);

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  }, [forceReload]);

  const handleClick = (event, row) => {
    let newArray = [...blacklistArray];
    if (event.target.checked) {
      newArray.push(row.partNumber);
    } else {
      const index = newArray.indexOf(row.partNumber);
      if (index > -1) {
        newArray.splice(index, 1);
      }
    }
    setBlacklistArray(newArray);
  };

  const handleSave = async () => {
    setIsLoading(true);
    await dispatch(removeFromBlacklist(manufacturer, blacklistArray));
    setBlacklistArray([]);
    loadData(true);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    setBlacklistArray([]);
    loadData(true, page);
  };

  return (
    <div className={classes.container}>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", zIndex: 100 }}>
          <CircularProgress />
        </Box>
      )}
      <div className={classes.row}>
        <h2>Blacklista {manufacturer}</h2>
        <Button variant="outlined" color="success" disabled={blacklistArray.length <= 0} onClick={handleSave}>
          Zapisz
        </Button>
      </div>
      {blacklist && !isLoading && (
        <TableContainer component={Paper} sx={{ padding: "0 10px 20px" }} className={classes.table}>
          <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ "& th": { padding: "5px 20px" } }}>
                <TableCell align="right">LP</TableCell>
                <TableCell align="left">Producent</TableCell>
                <TableCell align="left">PN</TableCell>
                <TableCell align="center">Usuń</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blacklist.map((row, index) => (
                <TableRow key={row.partNumber} sx={{ "& td, & th": { fontSize: "12px", padding: "5px 20px" } }}>
                  <TableCell scope="row" align="right">
                    {perPage * (currentPage - 1) + index + 1}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    sx={{ width: "120px", textTransform: "capitalize" }}
                  >
                    {row.manufacturer}
                  </TableCell>
                  <TableCell scope="row" align="left" width="100%">
                    {row.partNumber}
                  </TableCell>
                  <TableCell align="center" className={classes.checkColumn}>
                    <Checkbox size="small" onClick={(event) => handleClick(event, row)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className={classes.paginationRow}>
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
      </div>
    </div>
  );
}

export default InsertTab;
