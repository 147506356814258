import { uiActions } from "../slices/ui";
import { priceListActions } from "../slices/priceList";

import { sendRequest } from "./request";

export const getPriceList = (manufacturer, linkRef) => async (dispatch) => {
  try {
    const result = await dispatch(sendRequest("products/price-list", manufacturer, "GET", "file", linkRef));

    console.log(result);
  } catch (err) {
    // console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve();
};

export const uploadFile = (file) => async (dispatch) => {
  let fnResult = true;
  try {
    await dispatch(sendRequest("products/upload", file, "POST", "upload"));

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Dodano",
        message: "Plik dodany pomyślnie",
      })
    );
  } catch (err) {
    // console.log(err);
    fnResult = false;
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve(fnResult);
};

export const getProductsList = (manufacturer) => async (dispatch) => {
  try {
    const productsResult = await dispatch(sendRequest(`products/${manufacturer}`, {}, "GET"));

    if (manufacturer === "lenovo") {
      dispatch(priceListActions.setLenovo(productsResult));
    } else if (manufacturer === "dell") {
      dispatch(priceListActions.setDell(productsResult));
    } else if (manufacturer === "notebooki") {
      dispatch(priceListActions.setNotebooki(productsResult));
    } else if (manufacturer === "serwery") {
      dispatch(priceListActions.setSerwery(productsResult));
    }
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve();
};

export const setParsePriceList = (manufacturer) => async (dispatch) => {
  try {
    await dispatch(sendRequest("products/parse", { vendor: manufacturer }));

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Success!",
        message: "Lista produktów zaktualizowana",
      })
    );
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve();
};
