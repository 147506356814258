import { Box, Modal, Button, Typography } from "@mui/material";

import classes from "./DisabledProductsModal.module.css";

import { useDispatch } from "react-redux";

import { getDisabledProducts, deleteDisabledProduct } from "../../../store/actions/disabledProducts";

import config from "../../../config";
const { distributorObj } = config;

const DisabledProductsModal = (params) => {
  const dispatch = useDispatch();
  const handleClose = () => params.setIsModalOpen(false);

  const handleAccept = async (e) => {
    const saveResult = await dispatch(deleteDisabledProduct(params.disabledProduct));
    if (saveResult) {
      await dispatch(getDisabledProducts());
      params.setIsModalOpen(false);
    }
  };

  return (
    <div>
      <Modal
        open={params.isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.box}>
          <Typography id="product-disable-modal-title" variant="h6" component="h2" sx={{ marginTop: "10px" }}>
            {params?.disabledProduct?.partNumber}
          </Typography>
          <div className={classes.bodySection}>
            <p>
              Czy chcesz usunąć {params?.disabledProduct?.isDisabled ? "zablokowanie" : "odblokowanie"} tego PN dla{" "}
              <b>{distributorObj[params?.disabledProduct?.distributor]}</b>
            </p>
          </div>
          <div className={classes.centerRow}>
            <Button color="warning" onClick={handleClose} sx={{ marginRight: "5px" }}>
              Anuluj
            </Button>
            <Button color="success" variant="outlined" onClick={handleAccept} sx={{ marginLeft: "5px" }}>
              Akceptuj
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DisabledProductsModal;
