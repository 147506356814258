import { createSlice } from "@reduxjs/toolkit";

const disabledProductsSlice = createSlice({
  name: "disabledProducts",
  initialState: {
    disabledProducts: [],
  },
  reducers: {
    setDisabledProducts(state, action) {
      state.disabledProducts = action.payload;
    },
  },
});

export const disabledProductsActions = disabledProductsSlice.actions;

export default disabledProductsSlice.reducer;
