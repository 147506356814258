import { configureStore } from "@reduxjs/toolkit";

import uiSlice from "./slices/ui";
import authSlice from "./slices/auth";
import priceListSlice from "./slices/priceList";
import configSlice from "./slices/config";
import markupsSlice from "./slices/markups";
import accessoriesSlice from "./slices/accessories";
import fixedPricesSlice from "./slices/fixedPrices";
import insertListSlice from "./slices/insertList";
import blacklistSlice from "./slices/blacklist";
import disabledProductsSlice from "./slices/disabledProducts";
import distributorsSlice from "./slices/distributors";
import fixedMarkupsSlice from "./slices/fixedMarkups";
import wantedProductsSlice from "./slices/wantedProducts";

const store = configureStore({
  reducer: {
    ui: uiSlice,
    auth: authSlice,
    priceList: priceListSlice,
    config: configSlice,
    markups: markupsSlice,
    accessories: accessoriesSlice,
    fixedPrices: fixedPricesSlice,
    insertList: insertListSlice,
    blacklist: blacklistSlice,
    disabledProducts: disabledProductsSlice,
    distributors: distributorsSlice,
    fixedMarkups: fixedMarkupsSlice,
    wantedProducts: wantedProductsSlice,
  },
});

export default store;
