import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import classes from "./UploadFile.module.css";

import Button from "@mui/material/Button";

import { uploadFile } from "../../store/actions/priceList";
import { getConfig } from "../../store/actions/config";

function UploadFile({ company }) {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fileDetail, setFileDetail] = useState({});

  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    setFileDetail({
      size: selectedFile.size,
      type: selectedFile.type,
      name: selectedFile.name,
    });
  }, [selectedFile]);

  const fileChangeHandler = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFile(undefined);
      setIsFilePicked(false);
      return;
    }

    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleFileClear = () => {
    setIsFilePicked(false);
    setSelectedFile();
    setFileDetail({});
    document.querySelector(`#raised-button-file-${company.toLowerCase()}`).value = "";
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    if (isFilePicked) {
      let formData = new FormData();

      formData.append("uploadFile", selectedFile, company);

      const uploadResult = await dispatch(uploadFile(formData));
      console.log(uploadResult);
      if (uploadResult) {
        await dispatch(getConfig());
        handleFileClear();
      }
    }
  };

  const getSize = (size) => {
    return Math.round((size / 1024) * 100) / 100;
  };

  return (
    <div className={classes.container}>
      {isFilePicked && (
        <div className={classes.imageDetails}>
          <div className={classes.caption}>
            <p>{fileDetail.name}</p>
            <p>{getSize(fileDetail.size)} kB</p>
            <p>{fileDetail.type}</p>
          </div>
        </div>
      )}
      <input
        accept=".csv"
        className={classes.input}
        style={{ display: "none" }}
        id={`raised-button-file-${company.toLowerCase()}`}
        type="file"
        name="uploadFile"
        onChange={fileChangeHandler}
      />
      {!isFilePicked && (
        <label htmlFor={`raised-button-file-${company.toLowerCase()}`}>
          <Button variant="outlined" component="span" className={classes.button}>
            {company}
          </Button>
        </label>
      )}
      {isFilePicked && (
        <label>
          <Button variant="outlined" component="span" className={classes.button} color="warning" sx={{ mr: 1 }} onClick={handleFileClear}>
            Anuluj
          </Button>
          <Button variant="outlined" component="span" className={classes.button} color="success" sx={{ ml: 1 }} onClick={submitHandler}>
            Zapisz
          </Button>
        </label>
      )}
    </div>
  );
}

export default UploadFile;
