import React, { useState } from "react";

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import formatNumber from "../../formatNumber";

import AccessoriesModal from "./AccessoriesModal";
import classes from "./AccessoriesTable.module.css";

function AccessoriesTable({ accessoryList }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAccessory, setSelectedAccessory] = useState();
  const [selectedMode, setSelectedMode] = useState();

  const handleEdit = (item) => {
    setSelectedAccessory(item);
    setSelectedMode("edit");
    setIsModalOpen(true);
  };

  const handleDelete = (item) => {
    setSelectedAccessory(item);
    setSelectedMode("delete");
    setIsModalOpen(true);
  };

  return (
    <div>
      {accessoryList && (
        <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
          <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ "& th": { padding: "6px 6px" } }}>
                <TableCell sx={{ width: "80px" }} align="center">
                  LP
                </TableCell>
                <TableCell align="left">PN</TableCell>
                <TableCell align="left">Nazwa</TableCell>
                <TableCell sx={{ width: "200px" }} align="right">
                  Cena
                </TableCell>
                <TableCell sx={{ width: "70px" }} align="center">
                  Edytuj
                </TableCell>
                <TableCell sx={{ width: "70px" }} align="center">
                  Usuń
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accessoryList.map((row, index) => (
                <TableRow key={row.partNumber} sx={{ "& td, & th": { fontSize: "12px", padding: "3px 6px" } }}>
                  <TableCell scope="row" align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell scope="row" align="left">
                    {row.partNumber}
                  </TableCell>
                  <TableCell scope="row" align="left">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{formatNumber(row.price)}</TableCell>
                  <TableCell align="center">
                    <ModeEditIcon className={classes.editIcon} onClick={() => handleEdit(row)} />
                  </TableCell>
                  <TableCell align="center">
                    <DeleteIcon className={classes.editIcon} onClick={() => handleDelete(row)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {accessoryList && <AccessoriesModal isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} accessory={selectedAccessory} mode={selectedMode} />}
    </div>
  );
}

export default AccessoriesTable;
