import FixedPricesContent from "./FixedPrices/FixedPricesContent";
import DisabledProductsContent from "./DisabledProducts/DisabledProductsContent";
import DistributorTable from "./DistributorTable/DistributorTable";

import classes from "./FixedPricesSection.module.css";
import FixedMarkupsContent from "./FixedMarkups/FixedMarkupsContent";

function FixedPricesSection() {
  return (
    <div className={classes.container}>
      <FixedPricesContent />
      <DisabledProductsContent />
      <FixedMarkupsContent />
      <DistributorTable />
    </div>
  );
}

export default FixedPricesSection;
