import { uiActions } from "../slices/ui";
import { insertListActions } from "../slices/insertList";

import { sendRequest } from "./request";

export const getInsertList = (manufacturer, page) => async (dispatch) => {
  try {
    const insertListResult = await dispatch(sendRequest(`insert-list/${manufacturer}/?page=${page}`, {}, "GET"));

    if (manufacturer === "lenovo") {
      dispatch(insertListActions.setLenovo(insertListResult));
    } else if (manufacturer === "dell") {
      dispatch(insertListActions.setDell(insertListResult));
    } else if (manufacturer === "notebooki") {
      dispatch(insertListActions.setNotebooki(insertListResult));
    } else if (manufacturer === "serwery") {
      dispatch(insertListActions.setSerwery(insertListResult));
    }
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve();
};

export const removeFromInsertList = (manufacturer, partNumbers) => async (dispatch) => {
  try {
    await dispatch(sendRequest("insert-list", { vendor: manufacturer, partNumbers }, "DELETE"));

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Success!",
        message: "Dodano produkty do blacklisty",
      })
    );
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve();
};
