import { createSlice } from "@reduxjs/toolkit";

const priceListSlice = createSlice({
  name: "priceList",
  initialState: {
    lenovo: [],
    dell: [],
    notebooki: [],
    serwery: [],
  },
  reducers: {
    setLenovo(state, action) {
      state.lenovo = action.payload;
    },
    setDell(state, action) {
      state.dell = action.payload;
    },
    setNotebooki(state, action) {
      state.notebooki = action.payload;
    },
    setSerwery(state, action) {
      state.serwery = action.payload;
    },
  },
});

export const priceListActions = priceListSlice.actions;

export default priceListSlice.reducer;
