import { createSlice } from "@reduxjs/toolkit";

const blacklistSlice = createSlice({
  name: "blacklist",
  initialState: {
    lenovo: [],
    dell: [],
    notebooki: [],
    serwery: [],
  },
  reducers: {
    setLenovo(state, action) {
      state.lenovo = action.payload;
    },
    setDell(state, action) {
      state.dell = action.payload;
    },
    setNotebooki(state, action) {
      state.notebooki = action.payload;
    },
    setSerwery(state, action) {
      state.serwery = action.payload;
    },
  },
});

export const blacklistActions = blacklistSlice.actions;

export default blacklistSlice.reducer;
