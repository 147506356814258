import React, { useState } from "react";

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import formatNumber from "../../../formatNumber";

import FixedMarkupsModal from "./FixedMarkupsModal";
import classes from "./FixedMarkupsTable.module.css";

function FixedMarkupsTable({ fixedMarkupsList, searchInput }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFixedMarkup, setSelectedFixedMarkup] = useState();
  const [selectedMode, setSelectedMode] = useState();

  const handleEdit = (item) => {
    setSelectedFixedMarkup(item);
    setSelectedMode("edit");
    setIsModalOpen(true);
  };

  const handleDelete = (item) => {
    setSelectedFixedMarkup(item);
    setSelectedMode("delete");
    setIsModalOpen(true);
  };

  return (
    <div>
      {fixedMarkupsList && (
        <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
          <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ "& th": { padding: "6px 6px" } }}>
                <TableCell sx={{ width: "80px" }} align="center">
                  LP
                </TableCell>
                <TableCell align="left">PN</TableCell>
                <TableCell align="left">Nazwa</TableCell>
                <TableCell sx={{ width: "120px" }} align="right">
                  Kwota
                </TableCell>
                <TableCell sx={{ width: "120px" }} align="right">
                  Procent
                </TableCell>
                <TableCell sx={{ width: "70px" }} align="center">
                  Edytuj
                </TableCell>
                <TableCell sx={{ width: "70px" }} align="center">
                  Usuń
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fixedMarkupsList
                .filter((x) => searchInput === "" || x.partNumber.toLowerCase().includes(searchInput.toLowerCase()))
                .map((row, index) => (
                  <TableRow key={row.partNumber} sx={{ "& td, & th": { fontSize: "12px", padding: "3px 6px" } }}>
                    <TableCell scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell scope="row" align="left">
                      {row.partNumber}
                    </TableCell>
                    <TableCell scope="row" align="left">
                      {row.name ? row.name : "-"}
                    </TableCell>
                    <TableCell align="right">{row.price ? formatNumber(row.price) : "-"}</TableCell>
                    <TableCell align="right">{row.percent ? row.percent + "%" : "-"}</TableCell>
                    <TableCell align="center">
                      <ModeEditIcon className={classes.editIcon} onClick={() => handleEdit(row)} />
                    </TableCell>
                    <TableCell align="center">
                      <DeleteIcon className={classes.editIcon} onClick={() => handleDelete(row)} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {fixedMarkupsList && (
        <FixedMarkupsModal isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} fixedMarkup={selectedFixedMarkup} mode={selectedMode} />
      )}
    </div>
  );
}

export default FixedMarkupsTable;
