import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import useMountEffect from "../../hooks/useMountEffect";

import { getConfig, setConfig } from "../../store/actions/config";
import { getWantedProducts } from "../../store/actions/wantedProducts";
import { authActions } from "../../store/slices/auth";

import { setParsePriceList, getPriceList } from "../../store/actions/priceList";
import { priceListActions } from "../../store/slices/priceList";

import { Button, Box, CircularProgress } from "@mui/material";

import UploadFile from "./UploadFile";
import WantedProductsTable from "../WantedProducts/WantedProductsTable";

import classes from "./ConfigPageContent.module.css";

function ProductPageContent() {
  const dispatch = useDispatch();
  const linkRef = useRef();
  const forceReload = useSelector((state) => state.auth.forceReload);
  const config = useSelector((state) => state.config.dates);
  const wantedProducts = useSelector((state) => state.wantedProducts.foundWantedProducts);
  const [isLoading, setIsLoading] = useState(false);

  const loadData = async (force) => {
    if (force || (config && Object.keys(config).length <= 0)) {
      setIsLoading(true);
      await dispatch(getConfig());
      setIsLoading(false);
    }

    if (force || (wantedProducts && Object.keys(wantedProducts).length <= 0)) {
      setIsLoading(true);
      await dispatch(getWantedProducts());
      setIsLoading(false);
    }
  };

  useMountEffect(() => {
    loadData(false);
  });

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  }, [forceReload]);

  const handleParsePriceList = async (manufacturer) => {
    setIsLoading(true);
    await dispatch(setParsePriceList(manufacturer, linkRef));
    if (manufacturer === "lenovo") {
      dispatch(priceListActions.setLenovo([]));
    } else if (manufacturer === "dell") {
      dispatch(priceListActions.setDell([]));
    } else if (manufacturer === "notebooki") {
      dispatch(priceListActions.setNotebooki([]));
    } else if (manufacturer === "serwery") {
      dispatch(priceListActions.setSerwery([]));
    }
    loadData(true);
  };

  const handleGetPriceList = async (manufacturer) => {
    setIsLoading(true);
    await dispatch(getPriceList(manufacturer, linkRef));
    setIsLoading(false);
  };

  const returnDate = (date) => {
    return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "-";
  };

  const handleRefresh = async (distro) => {
    await dispatch(setConfig(distro));
    await dispatch(getConfig());
  };

  return (
    <>
      <div className={classes.header}>
        <h2>Konfiguracja</h2>
      </div>
      <div className={classes.section}>
        <div className={classes.column}>
          <h4>Dane pobrane z dystrybucji</h4>
          <div className={classes.row}>
            <div className={classes.col1}>
              <div className={classes.colRow}>TD:</div>
            </div>
            <div className={classes.col2}>
              <div className={classes.colRow}>{returnDate(config.tdDownload)}</div>
            </div>
            <div className={classes.col3}>
              <Button variant="text" className={classes.downloadButton} onClick={() => handleRefresh("td")}>
                Pobierz
              </Button>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col1}>
              <div className={classes.colRow}>AB:</div>
            </div>
            <div className={classes.col2}>
              <div className={classes.colRow}>{returnDate(config.abDownload)}</div>
            </div>
            <div className={classes.col3}>
              <Button variant="text" className={classes.downloadButton} onClick={() => handleRefresh("ab")}>
                Pobierz
              </Button>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col1}>
              <div className={classes.colRow}>INGRAM:</div>
            </div>
            <div className={classes.col2}>
              <div className={classes.colRow}>{returnDate(config.ingramDownload)}</div>
            </div>
            <div className={classes.col3}>
              <Button variant="text" className={classes.downloadButton} onClick={() => handleRefresh("ingram")}>
                Pobierz
              </Button>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col1}>
              <div className={classes.colRow}>ALSO:</div>
            </div>
            <div className={classes.col2}>
              <div className={classes.colRow}>{returnDate(config.alsoDownload)}</div>
            </div>
            <div className={classes.col3}>
              <Button variant="text" className={classes.downloadButton} onClick={() => handleRefresh("also")}>
                Pobierz
              </Button>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col1}>
              <div className={classes.colRow}>EET:</div>
            </div>
            <div className={classes.col2}>
              <div className={classes.colRow}>{returnDate(config.eetDownload)}</div>
            </div>
            <div className={classes.col3}>
              <Button variant="text" className={classes.downloadButton} onClick={() => handleRefresh("eet")}>
                Pobierz
              </Button>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col1}>
              <div className={classes.colRow}>ACTION:</div>
            </div>
            <div className={classes.col2}>
              <div className={classes.colRow}>{returnDate(config.actionDownload)}</div>
            </div>
            <div className={classes.col3}>
              <Button variant="text" className={classes.downloadButton} onClick={() => handleRefresh("action")}>
                Pobierz
              </Button>
            </div>
          </div>
          <h4>Dodaj cennik</h4>
          <div className={classes.row}>
            <div className={classes.col}>
              <div className={classes.col3}>{returnDate(config.dellFile)}</div>
              <UploadFile company="Dell" />
            </div>
            <div className={classes.col}>
              <div className={classes.col3}>{returnDate(config.lenovoFile)}</div>
              <UploadFile company="Lenovo" />
            </div>
            <div className={classes.col}>
              <div className={classes.col3}>{returnDate(config.notebookiFile)}</div>
              <UploadFile company="Notebooki" />
            </div>
            <div className={classes.col}>
              <div className={classes.col3}>{returnDate(config.serweryFile)}</div>
              <UploadFile company="Serwery" />
            </div>
          </div>
          <h4>Zaktualizuj dane z dystrybucji</h4>
          <div className={classes.row}>
            <div className={classes.col}>
              <div className={classes.colHeader}>Dell</div>
              {returnDate(config.dellUpdate)}
              <div className={classes.container}>
                <Button variant="outlined" onClick={() => handleParsePriceList("dell")}>
                  Zaktualizuj
                </Button>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.colHeader}>Lenovo</div>
              {returnDate(config.lenovoUpdate)}
              <div className={classes.container}>
                <Button variant="outlined" onClick={() => handleParsePriceList("lenovo")}>
                  Zaktualizuj
                </Button>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.colHeader}>Notebooki</div>
              {returnDate(config.notebookiUpdate)}
              <div className={classes.container}>
                <Button variant="outlined" onClick={() => handleParsePriceList("notebooki")}>
                  Zaktualizuj
                </Button>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.colHeader}>Serwery</div>
              {returnDate(config.serweryUpdate)}
              <div className={classes.container}>
                <Button variant="outlined" onClick={() => handleParsePriceList("serwery")}>
                  Zaktualizuj
                </Button>
              </div>
            </div>
          </div>
          <h4>Pobierz cennik</h4>
          <div className={classes.row}>
            <div className={classes.col}>
              <div className={classes.container}>
                <div className={classes.colHeader}>Dell</div>
                <Button variant="outlined" onClick={() => handleGetPriceList("dell")}>
                  Pobierz
                </Button>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.container}>
                <div className={classes.colHeader}>Lenovo</div>
                <Button variant="outlined" onClick={() => handleGetPriceList("lenovo")}>
                  Pobierz
                </Button>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.container}>
                <div className={classes.colHeader}>Notebooki</div>
                <Button variant="outlined" onClick={() => handleGetPriceList("notebooki")}>
                  Pobierz
                </Button>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.container}>
                <div className={classes.colHeader}>Serwery</div>
                <Button variant="outlined" onClick={() => handleGetPriceList("serwery")}>
                  Pobierz
                </Button>
              </div>
            </div>
          </div>
          <a ref={linkRef} />
          {isLoading && (
            <Box sx={{ position: "fixed", top: "200px", left: "50%" }}>
              <CircularProgress />
            </Box>
          )}
        </div>
        <div className={classes.column}>
          <h4>Znalezione produkty</h4>
          {wantedProducts && <WantedProductsTable wantedProductList={wantedProducts} />}
        </div>
      </div>
    </>
  );
}

export default ProductPageContent;
