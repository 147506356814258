import { useDispatch, useSelector } from "react-redux";
import useMountEffect from "../../hooks/useMountEffect";

import { getUser } from "../../store/actions/auth";

import classes from "./StartingPageContent.module.css";

const StartingPageContent = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const loadData = (force) => {
    dispatch(getUser());
  };

  useMountEffect(() => {
    loadData(false);
  });

  let message = "Witaj";
  if (user) {
    message += ` - ${user.name}`;
  }

  return (
    <section className={classes.starting}>
      <div className={classes.headerRow}>
        <h1>{message}</h1>
      </div>
    </section>
  );
};

export default StartingPageContent;
