import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { logout } from "../../store/actions/auth";
import { authActions } from "../../store/slices/auth";

import classes from "./MainNavigation.module.css";
import CachedIcon from "@mui/icons-material/Cached";
import { Stack, Menu, MenuItem } from "@mui/material";

const MainNavigation = () => {
  const dispatch = useDispatch();
  const [anchorInsertEl, setAnchorInsertEl] = useState(null);
  const [anchorBlacklistEl, setAnchorBlacklistEl] = useState(null);
  const [anchorProductsEl, setAnchorProductsEl] = useState(null);
  const [anchorCategoriesEl, setAnchorCategoriesEl] = useState(null);
  const openInsert = Boolean(anchorInsertEl);
  const openBlacklist = Boolean(anchorBlacklistEl);
  const openProducts = Boolean(anchorProductsEl);
  const openCategories = Boolean(anchorCategoriesEl);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const handleClick = (event) => {
    if (event.currentTarget.id === "nav-insert-list-button") {
      setAnchorInsertEl(event.currentTarget);
    } else if (event.currentTarget.id === "nav-products-button") {
      setAnchorProductsEl(event.currentTarget);
    } else if (event.currentTarget.id === "nav-blacklist-button") {
      setAnchorBlacklistEl(event.currentTarget);
    } else if (event.currentTarget.id === "nav-categories-button") {
      setAnchorCategoriesEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorInsertEl(null);
    setAnchorBlacklistEl(null);
    setAnchorProductsEl(null);
    setAnchorCategoriesEl(null);
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  const handleReload = () => {
    dispatch(authActions.setForceReload(true));
  };

  return (
    <header className={classes.header}>
      <Link to="/">
        <div className={classes.logo}>FAIM</div>
      </Link>
      {isLoggedIn && (
        <div className={classes.refresh}>
          <CachedIcon className={classes.refreshButton} onClick={handleReload} />
        </div>
      )}
      <nav>
        <ul>
          {!isLoggedIn && (
            <li>
              <Link to="/auth">Zaloguj</Link>
            </li>
          )}
          <Stack component="li" sx={{ display: { xs: "flex", lg: "none" }, color: "white" }}>
            <div
              id="nav-categories-button"
              className={classes.menuButton}
              aria-controls={open ? "nav-categories-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              Kategorie
            </div>
            <Menu
              id="nav-categories-menu"
              anchorEl={anchorCategoriesEl}
              open={openCategories}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "nav-categories-button",
              }}
            >
              <Link to="/markup">
                <MenuItem onClick={handleClose}>Stawki</MenuItem>
              </Link>
              <Link to="/fixed-prices">
                <MenuItem onClick={handleClose}>Ceny</MenuItem>
              </Link>
              <Link to="/accessories">
                <MenuItem onClick={handleClose}>Dodatki</MenuItem>
              </Link>
              <Link to="/wanted-products">
                <MenuItem onClick={handleClose}>Poszukiwane</MenuItem>
              </Link>
            </Menu>
          </Stack>
          <Stack sx={{ display: { xs: "none", lg: "flex" }, color: "white" }}>
            <ul>
              {isLoggedIn && (
                <li>
                  <Link to="/config">Konfiguracja</Link>
                </li>
              )}
              {isLoggedIn && (
                <li>
                  <Link to="/markup">Stawki</Link>
                </li>
              )}
              {isLoggedIn && (
                <li>
                  <Link to="/fixed-prices">Ceny</Link>
                </li>
              )}
              {isLoggedIn && (
                <li>
                  <Link to="/accessories">Dodatki</Link>
                </li>
              )}
              {isLoggedIn && (
                <li>
                  <Link to="/wanted-products">Poszukiwane</Link>
                </li>
              )}
            </ul>
          </Stack>
          {isLoggedIn && <div className={classes.divider}></div>}
          <Stack component="li" sx={{ display: { xs: "flex", lg: "none" }, color: "white" }}>
            <div
              id="nav-products-button"
              className={classes.menuButton}
              aria-controls={open ? "nav-products-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              Produkty
            </div>
            <Menu
              id="nav-products-menu"
              anchorEl={anchorProductsEl}
              open={openProducts}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "nav-products-button",
              }}
            >
              <Link to="/products/?manufacturer=dell">
                <MenuItem onClick={handleClose}>Dell</MenuItem>
              </Link>
              <Link to="/products/?manufacturer=lenovo">
                <MenuItem onClick={handleClose}>Lenovo</MenuItem>
              </Link>
              <Link to="/products/?manufacturer=notebooki">
                <MenuItem onClick={handleClose}>Notebooki</MenuItem>
              </Link>
              <Link to="/products/?manufacturer=serwery">
                <MenuItem onClick={handleClose}>Serwery</MenuItem>
              </Link>
            </Menu>
          </Stack>
        </ul>
        <Stack sx={{ display: { xs: "none", lg: "flex" }, color: "white" }}>
          <ul>
            {isLoggedIn && (
              <li>
                <Link to="/products/?manufacturer=dell">Dell</Link>
              </li>
            )}
            {isLoggedIn && (
              <li>
                <Link to="/products/?manufacturer=lenovo">Lenovo</Link>
              </li>
            )}
            {isLoggedIn && (
              <li>
                <Link to="/products/?manufacturer=notebooki">Notebooki</Link>
              </li>
            )}
            {isLoggedIn && (
              <li>
                <Link to="/products/?manufacturer=serwery">Serwery</Link>
              </li>
            )}
          </ul>
        </Stack>
        <ul>
          {isLoggedIn && <div className={classes.divider}></div>}
          {isLoggedIn && (
            <li>
              <div
                id="nav-insert-list-button"
                className={classes.menuButton}
                aria-controls={open ? "nav-insert-list-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                DD
              </div>
              <Menu
                id="nav-insert-list-menu"
                anchorEl={anchorInsertEl}
                open={openInsert}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "nav-insert-list-button",
                }}
              >
                <Link to="/insert-list/?manufacturer=dell">
                  <MenuItem onClick={handleClose}>Dell</MenuItem>
                </Link>
                <Link to="/insert-list/?manufacturer=lenovo">
                  <MenuItem onClick={handleClose}>Lenovo</MenuItem>
                </Link>
                <Link to="/insert-list/?manufacturer=notebooki">
                  <MenuItem onClick={handleClose}>Notebooki</MenuItem>
                </Link>
                <Link to="/insert-list/?manufacturer=serwery">
                  <MenuItem onClick={handleClose}>Serwery</MenuItem>
                </Link>
              </Menu>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <div
                id="nav-blacklist-button"
                className={classes.menuButton}
                aria-controls={open ? "nav-blacklist-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                BL
              </div>
              <Menu
                id="nav-blacklist-menu"
                anchorEl={anchorBlacklistEl}
                open={openBlacklist}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "nav-blacklist-button",
                }}
              >
                <Link to="/blacklist/?manufacturer=dell">
                  <MenuItem onClick={handleClose}>Dell</MenuItem>
                </Link>
                <Link to="/blacklist/?manufacturer=lenovo">
                  <MenuItem onClick={handleClose}>Lenovo</MenuItem>
                </Link>
                <Link to="/blacklist/?manufacturer=notebooki">
                  <MenuItem onClick={handleClose}>Notebooki</MenuItem>
                </Link>
                <Link to="/blacklist/?manufacturer=serwery">
                  <MenuItem onClick={handleClose}>Serwery</MenuItem>
                </Link>
              </Menu>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <button className={classes.logoutButton} onClick={logoutHandler}>
                Wyloguj
              </button>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;
