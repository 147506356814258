import { uiActions } from "../slices/ui";
import { distributorsActions } from "../slices/distributors";

import { sendRequest } from "./request";

export const getDistributors = () => async (dispatch) => {
  let result = [];
  try {
    result = await dispatch(sendRequest("distributors", {}, "GET"));

    dispatch(distributorsActions.setDistributors(result));
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve(result);
};

export const setDistributor = (distributor) => async (dispatch) => {
  let result = false;
  try {
    await dispatch(sendRequest("distributors", distributor));

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Success!",
        message: "Rekord dodany",
      })
    );
    result = true;
  } catch (err) {
    console.log(err);
    if (err.code === 409) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Error!",
          message: "Istniejący klucz!",
        })
      );
    } else {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Błąd zapisu!",
        })
      );
    }
  }
  return Promise.resolve(result);
};
