import { createSlice } from "@reduxjs/toolkit";

const markupsSlice = createSlice({
  name: "markups",
  initialState: {
    markups: [],
  },
  reducers: {
    setMarkups(state, action) {
      state.markups = action.payload;
    },
  },
});

export const markupsActions = markupsSlice.actions;

export default markupsSlice.reducer;
