import { uiActions } from "../slices/ui";
import { configActions } from "../slices/config";

import { sendRequest } from "./request";

export const getConfig = () => async (dispatch) => {
  try {
    const configResult = await dispatch(sendRequest("config-dates", {}, "GET"));

    dispatch(configActions.setDates(configResult));
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve();
};

export const setConfig = (distribution) => async (dispatch) => {
  try {
    await dispatch(sendRequest(`config-dates/${distribution}`, {}));

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Success!",
        message: "Pobieranie zakończone",
      })
    );
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve();
};
