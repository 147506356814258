import React, { useState } from "react";

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

import DisabledProductsModal from "./DisabledProductsModal";
import classes from "./DisabledProductsTable.module.css";

import config from "../../../config";
const { distributorObj } = config;

function DisabledProductsTable({ disabledProductsList, searchInput }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDisabledProduct, setSelectedDisabledProduct] = useState();

  const handleDelete = (item) => {
    setSelectedDisabledProduct(item);
    setIsModalOpen(true);
  };

  return (
    <div>
      {disabledProductsList && (
        <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
          <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ "& th": { padding: "6px 6px" } }}>
                <TableCell sx={{ width: "80px" }} align="center">
                  LP
                </TableCell>
                <TableCell sx={{ width: "100px" }} align="left">
                  Dytrybucja
                </TableCell>
                <TableCell align="left">PN</TableCell>
                <TableCell align="left">Nazwa</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell sx={{ width: "70px" }} align="center">
                  Usuń
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {disabledProductsList
                .filter((x) => searchInput === "" || x.partNumber.toLowerCase().includes(searchInput.toLowerCase()))
                .map((row, index) => (
                  <TableRow
                    key={`${row.partNumber}_${row.distributor}`}
                    sx={{ "& td, & th": { fontSize: "12px", padding: "3px 6px" } }}
                  >
                    <TableCell scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{row.distributor ? distributorObj[row.distributor] : "-"}</TableCell>
                    <TableCell scope="row" align="left">
                      {row.partNumber}
                    </TableCell>
                    <TableCell scope="row" align="left">
                      {row.name ? row.name : "-"}
                    </TableCell>
                    <TableCell scope="row" align="center">
                      {row.isDisabled ? <CloseIcon color="warning" /> : <DoneIcon color="success" />}
                    </TableCell>
                    <TableCell align="center">
                      <DeleteIcon className={classes.editIcon} onClick={() => handleDelete(row)} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {disabledProductsList && (
        <DisabledProductsModal
          isOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          disabledProduct={selectedDisabledProduct}
        />
      )}
    </div>
  );
}

export default DisabledProductsTable;
