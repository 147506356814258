const API_URL = `https://price-backend.faim.pl/`;
const DEV_API_URL = `http://localhost:8090/`;

const config = {
  dev: process.env.NODE_ENV,
  apiUrl: process.env.NODE_ENV === "development" ? DEV_API_URL : API_URL,
  distributorObj: {
    td: "TechData",
    ab: "AB",
    ingram: "Ingram",
    also: "Also",
    eet: "EET",
    action: "Action",
    accessory: "Akcesoria",
    fixedPrice: "Ceny ustalone",
  },
  distributorsDict: [
    {
      label: "TechData",
      value: "td",
    },
    {
      label: "AB",
      value: "ab",
    },
    {
      label: "Ingram",
      value: "ingram",
    },
    {
      label: "Also",
      value: "also",
    },
    {
      label: "EET",
      value: "eet",
    },
    {
      label: "Action",
      value: "action",
    },
  ],
};

export default config;
