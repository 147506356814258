import { createSlice } from "@reduxjs/toolkit";

const wantedProductsSlice = createSlice({
  name: "wantedProducts",
  initialState: {
    wantedProducts: [],
    foundWantedProducts: [],
  },
  reducers: {
    setWantedProducts(state, action) {
      state.wantedProducts = action.payload;
    },
    setFoundWantedProducts(state, action) {
      state.foundWantedProducts = action.payload;
    },
  },
});

export const wantedProductsActions = wantedProductsSlice.actions;

export default wantedProductsSlice.reducer;
