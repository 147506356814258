import { uiActions } from "../slices/ui";
import { wantedProductsActions } from "../slices/wantedProducts";

import { sendRequest } from "./request";

export const getWantedProducts = () => async (dispatch) => {
  let result = [];
  try {
    result = await dispatch(sendRequest("wanted-products", {}, "GET"));

    const foundWantedProducts = result.filter((x) => x.isFound);

    dispatch(wantedProductsActions.setWantedProducts(result));
    dispatch(wantedProductsActions.setFoundWantedProducts(foundWantedProducts));
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve(result);
};

export const setWantedProduct = (wantedProduct) => async (dispatch) => {
  let result = false;
  try {
    await dispatch(sendRequest("wanted-products", wantedProduct));

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Success!",
        message: "Rekord dodany",
      })
    );
    result = true;
  } catch (err) {
    console.log(err);
    if (err.code === 409) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Error!",
          message: "Istniejący klucz!",
        })
      );
    } else {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Błąd zapisu!",
        })
      );
    }
  }
  return Promise.resolve(result);
};

export const deleteWantedProduct = (wantedProduct) => async (dispatch) => {
  let result = false;
  try {
    await dispatch(sendRequest(`wanted-products/${wantedProduct._id}`, {}, "DELETE"));

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Success!",
        message: "Rekord usunięty",
      })
    );
    result = true;
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd zapisu!",
      })
    );
  }
  return Promise.resolve(result);
};
