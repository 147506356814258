const formatNumber = (value) => {
  let decimals = 2;
  let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
  let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

  let n = Math.abs(value).toFixed(decimals).split(".");
  n[0] = n[0]
    .split("")
    .reverse()
    .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
    .reverse()
    .join("");
  let final = (Math.sign(value) < 0 ? "-" : "") + n.join(decpoint);

  return final;
};

export default formatNumber;
