import React, { useState } from "react";

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import WantedProductsModal from "./WantedProductsModal";
import classes from "./WantedProductsTable.module.css";

function WantedProductsTable({ wantedProductList }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWantedProduct, setSelectedWantedProduct] = useState();

  const handleDelete = (item) => {
    setSelectedWantedProduct(item);
    setIsModalOpen(true);
  };

  return (
    <div>
      {wantedProductList && wantedProductList.length > 0 && (
        <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
          <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ "& th": { padding: "6px 6px" } }}>
                <TableCell sx={{ width: "80px" }} align="center">
                  LP
                </TableCell>
                <TableCell align="left">PN</TableCell>
                <TableCell align="left">Nazwa</TableCell>
                <TableCell sx={{ width: "70px" }} align="center">
                  Usuń
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wantedProductList.map((row, index) => (
                <TableRow key={row.partNumber} sx={{ "& td, & th": { fontSize: "12px", padding: "3px 6px" } }}>
                  <TableCell scope="row" align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell scope="row" align="left">
                    {row.partNumber}
                  </TableCell>
                  <TableCell scope="row" align="left">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">
                    <DeleteIcon className={classes.editIcon} onClick={() => handleDelete(row)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {wantedProductList && wantedProductList.length <= 0 && <div className={classes.noData}>Brak danych</div>}
      {wantedProductList && <WantedProductsModal isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} wantedProduct={selectedWantedProduct} />}
    </div>
  );
}

export default WantedProductsTable;
