import { createSlice } from "@reduxjs/toolkit";

const fixedPricesSlice = createSlice({
  name: "fixedPrices",
  initialState: {
    fixedPrices: [],
  },
  reducers: {
    setFixedPrices(state, action) {
      state.fixedPrices = action.payload;
    },
  },
});

export const fixedPricesActions = fixedPricesSlice.actions;

export default fixedPricesSlice.reducer;
