import React, { useState } from "react";

import ProductTab from "./ProductTab";
import classes from "./ProductListContent.module.css";

import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function ProductListContent({ manufacturer }) {
  const [searchInput, setSearchInput] = useState("");

  const handleSetSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  return (
    <div>
      <div className={classes.row}>
        <h2>Produkty {manufacturer}</h2>
        <TextField
          className={classes.searchInput}
          id="manufacturer-search-input"
          label="Szukaj PN"
          value={searchInput}
          onChange={handleSetSearchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          type="search"
          size="small"
        />
      </div>
      <ProductTab manufacturer={manufacturer} searchInput={searchInput} />
    </div>
  );
}

export default ProductListContent;
