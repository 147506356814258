import { uiActions } from "../slices/ui";
import { blacklistActions } from "../slices/blacklist";

import { sendRequest } from "./request";

export const getBlacklist = (manufacturer, page) => async (dispatch) => {
  try {
    const blacklistResult = await dispatch(sendRequest(`black-list/${manufacturer}/?page=${page}`, {}, "GET"));

    if (manufacturer === "lenovo") {
      dispatch(blacklistActions.setLenovo(blacklistResult));
    } else if (manufacturer === "dell") {
      dispatch(blacklistActions.setDell(blacklistResult));
    } else if (manufacturer === "notebooki") {
      dispatch(blacklistActions.setNotebooki(blacklistResult));
    } else if (manufacturer === "serwery") {
      dispatch(blacklistActions.setSerwery(blacklistResult));
    }
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve();
};

export const removeFromBlacklist = (manufacturer, partNumbers) => async (dispatch) => {
  try {
    await dispatch(sendRequest("black-list", { vendor: manufacturer, partNumbers }, "DELETE"));

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Success!",
        message: "Usunięto produkty z blacklisty",
      })
    );
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve();
};
