import ProductListContent from "../components/ProductList/ProductListContent";
import useQuery from "../hooks/useQuery";

const ProductList = () => {
  const query = useQuery();

  return <ProductListContent manufacturer={query.get("manufacturer")} />;
};

export default ProductList;
