import { Routes, Route, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { uiActions } from "./store/slices/ui";

import Layout from "./components/Layout/Layout";
import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/HomePage";
import ConfigPage from "./pages/ConfigPage";
import ProductListPage from "./pages/ProductListPage";
import MarkupPage from "./pages/MarkupPage";
import AccessoriesPage from "./pages/AccessoriesPage";
import FixedPricesPage from "./pages/FixedPricesPage";
import InsertListPage from "./pages/InsertListPage";
import BlacklistPage from "./pages/BlacklistPage";
import WantedProductsPage from "./pages/WantedProductsPage";

import { Alert } from "@mui/material";
import Fade from "@mui/material/Fade";

import { retrieveToken } from "./store/actions/auth";

let isInitial = true;

function App() {
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => state.ui.isVisible);
  const notification = useSelector((state) => state.ui.notification);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    if (isInitial) {
      dispatch(retrieveToken());
      isInitial = false;
    }
  }, [dispatch]);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        dispatch(uiActions.toggleNotification());
      }, 3000);
    }
  }, [isVisible, dispatch]);

  if (!isLoggedIn)
    return (
      <>
        <Layout>
          <AuthPage />
        </Layout>
        <Fade in={isVisible}>
          <Alert
            style={{ position: "fixed", bottom: 20, right: 20, minWidth: "300px" }}
            severity={notification.status}
            variant="filled"
          >
            {notification.message}
          </Alert>
        </Fade>
      </>
    );

  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<ConfigPage />}></Route>
          <Route path="/config" element={<ConfigPage />}></Route>
          <Route path="/insert-list" element={<InsertListPage />}></Route>
          <Route path="/blacklist" element={<BlacklistPage />}></Route>
          <Route path="/fixed-prices" element={<FixedPricesPage />}></Route>
          <Route path="/accessories" element={<AccessoriesPage />}></Route>
          <Route path="/products" element={<ProductListPage />}></Route>
          <Route path="/markup" element={<MarkupPage />}></Route>
          <Route path="/wanted-products" element={<WantedProductsPage />}></Route>
          <Route path="/auth" element={!isLoggedIn ? <AuthPage /> : <HomePage />}></Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
      <Fade in={isVisible}>
        <Alert
          style={{ position: "fixed", bottom: 20, right: 20, minWidth: "300px" }}
          severity={notification.status}
          variant="filled"
        >
          {notification.message}
        </Alert>
      </Fade>
    </>
  );
}

export default App;
