import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "config",
  initialState: {
    dates: {},
  },
  reducers: {
    setDates(state, action) {
      state.dates = action.payload;
    },
  },
});

export const configActions = configSlice.actions;

export default configSlice.reducer;
