import React, { useState, useEffect } from "react";

import { Box, Modal, TextField, Button, Typography, MenuItem } from "@mui/material";

import classes from "./FixedMarkupsModal.module.css";

import { useDispatch } from "react-redux";

import { getFixedMarkups, setFixedMarkup, deleteFixedMarkup } from "../../../store/actions/fixedMarkups";

const FixedMarkupsModal = (params) => {
  const dispatch = useDispatch();
  const handleClose = () => params.setIsModalOpen(false);
  const [fixedMarkupForm, setFixedMarkupForm] = useState();

  useEffect(() => {
    let form;
    if (params.fixedMarkup) {
      form = JSON.parse(JSON.stringify(params.fixedMarkup));
      form.price = form.price ? form.price : 0;
      form.percent = form.percent ? form.percent : 0;
    }
    setFixedMarkupForm(form);
  }, [params.fixedMarkup]);

  const handleFormChange = (event) => {
    const target = event.target.name;
    let newForm = JSON.parse(JSON.stringify(fixedMarkupForm));
    if (typeof newForm[target] === "undefined") {
      return console.log(target + " not found!");
    }
    newForm[target] = event.target.value;

    setFixedMarkupForm(newForm);
  };

  const handleAccept = async (e) => {
    let saveResult;
    if (params.mode === "edit") {
      saveResult = await dispatch(setFixedMarkup(fixedMarkupForm));
    } else if (params.mode === "delete") {
      saveResult = await dispatch(deleteFixedMarkup(fixedMarkupForm));
    }
    if (saveResult) {
      await dispatch(getFixedMarkups());
      params.setIsModalOpen(false);
    }
  };

  return (
    <div>
      <Modal open={params.isOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className={classes.box}>
          <Typography id="fixed-markup-modal-title" variant="h6" component="h2" sx={{ marginTop: "10px" }}>
            {fixedMarkupForm?.partNumber}
          </Typography>
          <div className={classes.bodySection}>
            {params.mode === "edit" && (
              <div className={classes.inputSection}>
                <TextField
                  id="fixed_markups_modal_price_input"
                  label="Kwota"
                  type="number"
                  name="price"
                  value={fixedMarkupForm?.price}
                  size="small"
                  onChange={handleFormChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="dense"
                />
                <TextField
                  id="fixed_markups_modal_percent_input"
                  label="Procent"
                  type="number"
                  inputProps={{ step: 0.01 }}
                  name="percent"
                  value={fixedMarkupForm?.percent}
                  size="small"
                  onChange={handleFormChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="dense"
                />
              </div>
            )}
            {params.mode === "delete" && <p>Czy chcesz usunąć tą marżę?</p>}
          </div>
          <div className={classes.centerRow}>
            <Button color="warning" onClick={handleClose} sx={{ marginRight: "5px" }}>
              Anuluj
            </Button>
            <Button color="success" variant="outlined" onClick={handleAccept} sx={{ marginLeft: "5px" }}>
              Akceptuj
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FixedMarkupsModal;
