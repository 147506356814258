import React, { useState, useEffect } from "react";

import { Box, Modal, TextField, Button, Typography } from "@mui/material";

import classes from "./AccessoriesModal.module.css";

import { useDispatch } from "react-redux";

import { getAccessories, setAccessory, deleteAccessory } from "../../store/actions/accessories";

const AccessoriesModal = (params) => {
  const dispatch = useDispatch();
  const handleClose = () => params.setIsModalOpen(false);
  const [accessoryForm, setAccessoryForm] = useState();

  useEffect(() => {
    setAccessoryForm(params.accessory);
  }, [params.accessory]);

  const handleFormChange = (event) => {
    const target = event.target.name;
    let newForm = JSON.parse(JSON.stringify(accessoryForm));
    if (typeof newForm[target] === "undefined") {
      return console.log(target + " not found!");
    }
    newForm[target] = event.target.value;

    setAccessoryForm(newForm);
  };

  const handleAccept = async (e) => {
    let saveResult;
    if (params.mode === "edit") {
      saveResult = await dispatch(setAccessory(accessoryForm));
    } else if (params.mode === "delete") {
      saveResult = await dispatch(deleteAccessory(accessoryForm));
    }
    if (saveResult) {
      await dispatch(getAccessories());
      params.setIsModalOpen(false);
    }
  };

  return (
    <div>
      <Modal open={params.isOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className={classes.box}>
          <Typography id="accessory-modal-title" variant="h6" component="h2" sx={{ marginTop: "10px" }}>
            {accessoryForm?.partNumber}
          </Typography>
          <div className={classes.inputSection}>
            {params.mode === "edit" && (
              <TextField
                id="accessories_modal_price_input"
                label="Cena"
                type="number"
                name="price"
                value={accessoryForm?.price}
                size="small"
                onChange={handleFormChange}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="dense"
              />
            )}
            {params.mode === "delete" && <p>Czy chcesz usunąć ten dodatek?</p>}
          </div>
          <div className={classes.centerRow}>
            <Button color="warning" onClick={handleClose} sx={{ marginRight: "5px" }}>
              Anuluj
            </Button>
            <Button color="success" variant="outlined" onClick={handleAccept} sx={{ marginLeft: "5px" }}>
              Akceptuj
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AccessoriesModal;
