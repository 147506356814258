import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
} from "@mui/material";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

import { getProductsList } from "../../store/actions/priceList";
import { authActions } from "../../store/slices/auth";
import classes from "./ProductTab.module.css";

import formatNumber from "../../formatNumber";

function ProductTab({ manufacturer, searchInput }) {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.priceList[manufacturer]);
  const forceReload = useSelector((state) => state.auth.forceReload);

  const [isLoading, setIsLoading] = useState(false);

  const loadData = async (force) => {
    if (force || (productList && Object.keys(productList).length <= 0)) {
      setIsLoading(true);
      await dispatch(getProductsList(manufacturer));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(manufacturer);
    loadData(false);
  }, [manufacturer]);

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  }, [forceReload]);

  const selectSourceStyle = (selectedSource, manufacturer, color = "blue") => {
    if (typeof manufacturer === "object") {
      if (selectedSource === manufacturer[0]) {
        return `color: ${color[0]}`;
      } else if (selectedSource === manufacturer[1]) {
        return `color: ${color[1]}`;
      }
    }
    if (selectedSource === manufacturer) return `color: ${color}`;
    return "";
  };

  const tooltipDict = {
    td: "TechData",
    also: "Also",
    ingram: "Ingram",
    action: "Action",
    eet: "EET",
    ab: "AB",
    accessory: "Akcesoria",
    fixedPrice: "Ceny ustalone",
  };

  return (
    <div>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", zIndex: 100 }}>
          <CircularProgress />
        </Box>
      )}
      {productList && !isLoading && (
        <TableContainer component={Paper} sx={{ padding: "0 10px 20px" }}>
          <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ "& th": { padding: "8px 8px" } }}>
                <TableCell align="right">LP</TableCell>
                <TableCell align="left">PN</TableCell>
                <TableCell align="left">Nazwa</TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>Cennik zł</div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>
                    <Inventory2OutlinedIcon className={classes.headerIcon} />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>TD zł</div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>
                    <Inventory2OutlinedIcon className={classes.headerIcon} />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>AB zł</div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>
                    <Inventory2OutlinedIcon className={classes.headerIcon} />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>Ingram zł</div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>
                    <Inventory2OutlinedIcon className={classes.headerIcon} />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>Also zł</div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>
                    <Inventory2OutlinedIcon className={classes.headerIcon} />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>EET zł</div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>
                    <Inventory2OutlinedIcon className={classes.headerIcon} />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>Action zł</div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>
                    <Inventory2OutlinedIcon className={classes.headerIcon} />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productList
                .filter((x) => searchInput === "" || x.partNumber.toLowerCase().includes(searchInput.toLowerCase()))
                .map((row, index) => (
                  <TableRow key={row.partNumber} sx={{ "& td, & th": { fontSize: "12px", padding: "3px 6px" } }}>
                    <TableCell scope="row" align="right">
                      {index + 1}
                    </TableCell>
                    <TableCell scope="row" align="left">
                      {row.partNumber}
                    </TableCell>
                    <TableCell component="th" scope="row" align="left" className={classes.tdBorder}>
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.finalCell}
                      sx={selectSourceStyle(row.selectedSource, ["fixedPrice", "accessory"], ["green", "red"])}
                    >
                      {row.selectedSource && (
                        <Tooltip title={tooltipDict[row.selectedSource]} placement="top">
                          <span>{formatNumber(row.finalPrice)}</span>
                        </Tooltip>
                      )}
                      {!row.selectedSource && <span>{formatNumber(row.finalPrice)}</span>}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={row.finalStock > 0 ? "font-weight: bold" : ""}
                      className={`${classes.finalCell} ${classes.tdBorder}`}
                    >
                      {row.finalStock}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={selectSourceStyle(row.selectedSource, "td")}
                      className={classes.priceColumn}
                    >
                      {formatNumber(row.tdPrice)}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={row.tdStock > 0 ? "font-weight: bold" : ""}
                      className={classes.tdBorder}
                    >
                      {row.tdStock}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={selectSourceStyle(row.selectedSource, "ab")}
                      className={classes.priceColumn}
                    >
                      {formatNumber(row.abPrice)}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={row.abStock > 0 ? "font-weight: bold" : ""}
                      className={classes.tdBorder}
                    >
                      {row.abStock}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={selectSourceStyle(row.selectedSource, "ingram")}
                      className={classes.priceColumn}
                    >
                      {formatNumber(row.ingramPrice)}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={row.ingramStock > 0 ? "font-weight: bold" : ""}
                      className={classes.tdBorder}
                    >
                      {row.ingramStock}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={selectSourceStyle(row.selectedSource, "also")}
                      className={classes.priceColumn}
                    >
                      {formatNumber(row.alsoPrice)}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={row.alsoStock > 0 ? "font-weight: bold" : ""}
                      className={classes.tdBorder}
                    >
                      {row.alsoStock}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={selectSourceStyle(row.selectedSource, "eet")}
                      className={classes.priceColumn}
                    >
                      {formatNumber(row.eetPrice)}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={row.eetStock > 0 ? "font-weight: bold" : ""}
                      className={classes.tdBorder}
                    >
                      {row.eetStock}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={selectSourceStyle(row.selectedSource, "action")}
                      className={classes.priceColumn}
                    >
                      {formatNumber(row.actionPrice)}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={row.actionStock > 0 ? "font-weight: bold" : ""}
                      className={classes.tdBorder}
                    >
                      {row.actionStock}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default ProductTab;
