import { useState, useRef } from "react";
import { useDispatch } from "react-redux";

import { login } from "../../store/actions/auth";

import classes from "./AuthForm.module.css";

const AuthForm = () => {
  const dispatch = useDispatch();
  const loginInputRef = useRef();
  const passwordInputRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredLogin = loginInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    const credentials = {
      email: enteredLogin,
      password: enteredPassword,
    };

    setIsLoading(true);

    dispatch(login(credentials));

    setIsLoading(false);
  };

  return (
    <section className={classes.auth}>
      <h1>Zaloguj</h1>
      <form onSubmit={submitHandler}>
        <div className={classes.control}>
          <label htmlFor="login">Login</label>
          <input type="text" id="login" required ref={loginInputRef} />
        </div>
        <div className={classes.control}>
          <label htmlFor="password">Hasło</label>
          <input type="password" id="password" required ref={passwordInputRef} />
        </div>
        <div className={classes.actions}>
          <button>Login</button>
          {isLoading && <p>Wczytywanie...</p>}
        </div>
      </form>
    </section>
  );
};

export default AuthForm;
