import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useMountEffect from "../../hooks/useMountEffect";

import { Box, CircularProgress, TextField, Button } from "@mui/material";

import { getAccessories, setAccessory } from "../../store/actions/accessories";
import { authActions } from "../../store/slices/auth";

import classes from "./AccessoriesContent.module.css";

import AccessoriesTable from "./AccessoriesTable";

function AccessoriesContent() {
  const dispatch = useDispatch();
  const accessoryList = useSelector((state) => state.accessories.accessories);
  const forceReload = useSelector((state) => state.auth.forceReload);

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ partNumber: "", price: "" });

  const handleFormChange = (event) => {
    const target = event.target.name;
    let newForm = JSON.parse(JSON.stringify(form));
    if (typeof newForm[target] === "undefined") {
      return console.log(target + " not found!");
    }
    newForm[target] = event.target.value;

    setForm(newForm);
  };

  const loadData = async (force) => {
    if (force || (accessoryList && Object.keys(accessoryList).length <= 0)) {
      setIsLoading(true);
      await dispatch(getAccessories());
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  }, [forceReload]);

  useMountEffect(() => {
    loadData();
  });

  const handleSave = async () => {
    const saveResult = await dispatch(setAccessory(form));
    if (saveResult) {
      setForm({ partNumber: "", price: "" });
      loadData(true);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <h2>Dodatki</h2>
      </div>
      <div className={classes.section}>
        <div className={classes.row}>
          <TextField
            id="accessories_pn_input"
            className={classes.textField}
            label="PartNumber"
            type="text"
            name="partNumber"
            value={form.partNumber}
            size="small"
            onChange={handleFormChange}
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
          />
          <TextField
            id="accessories_price_input"
            className={classes.textField}
            label="Cena"
            type="number"
            name="price"
            value={form.price}
            size="small"
            onChange={handleFormChange}
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
          />
          <Button color="success" onClick={handleSave}>
            Zapisz
          </Button>
        </div>
      </div>
      <div className={classes.section}>{accessoryList && <AccessoriesTable accessoryList={accessoryList} />}</div>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", zIndex: 100 }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default AccessoriesContent;
