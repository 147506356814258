import BlacklistContent from "../components/Blacklist/BlacklistContent";
import useQuery from "../hooks/useQuery";

const Blacklist = () => {
  const query = useQuery();

  return <BlacklistContent manufacturer={query.get("manufacturer")} />;
};

export default Blacklist;
