import React, { useState } from "react";

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import formatNumber from "../../../formatNumber";

import FixedPricesModal from "./FixedPricesModal";
import classes from "./FixedPricesTable.module.css";

function FixedPricesTable({ fixedPricesList }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFixedPrice, setSelectedFixedPrice] = useState();
  const [selectedMode, setSelectedMode] = useState();

  const handleEdit = (item) => {
    setSelectedFixedPrice(item);
    setSelectedMode("edit");
    setIsModalOpen(true);
  };

  const handleDelete = (item) => {
    setSelectedFixedPrice(item);
    setSelectedMode("delete");
    setIsModalOpen(true);
  };

  return (
    <div>
      {fixedPricesList && (
        <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
          <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ "& th": { padding: "6px 6px" } }}>
                <TableCell sx={{ width: "80px" }} align="center">
                  LP
                </TableCell>
                <TableCell align="left">PN</TableCell>
                <TableCell align="left">Nazwa</TableCell>
                <TableCell sx={{ width: "200px" }} align="right">
                  Cena
                </TableCell>
                <TableCell sx={{ width: "200px" }} align="right">
                  Status
                </TableCell>
                <TableCell sx={{ width: "100px" }} align="right">
                  Aktywny
                </TableCell>
                <TableCell sx={{ width: "70px" }} align="center">
                  Edytuj
                </TableCell>
                <TableCell sx={{ width: "70px" }} align="center">
                  Usuń
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fixedPricesList.map((row, index) => (
                <TableRow key={row.partNumber} sx={{ "& td, & th": { fontSize: "12px", padding: "3px 6px" } }}>
                  <TableCell scope="row" align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell scope="row" align="left">
                    {row.partNumber}
                  </TableCell>
                  <TableCell scope="row" align="left">
                    {row.name ? row.name : "-"}
                  </TableCell>
                  <TableCell align="right">{row.price ? formatNumber(row.price) : "-"}</TableCell>
                  <TableCell align="right">{row.status ? row.status : "-"}</TableCell>
                  <TableCell align="right">{row.active ? row.active : "-"}</TableCell>
                  <TableCell align="center">
                    <ModeEditIcon className={classes.editIcon} onClick={() => handleEdit(row)} />
                  </TableCell>
                  <TableCell align="center">
                    <DeleteIcon className={classes.editIcon} onClick={() => handleDelete(row)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {fixedPricesList && (
        <FixedPricesModal isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} fixedPrice={selectedFixedPrice} mode={selectedMode} />
      )}
    </div>
  );
}

export default FixedPricesTable;
