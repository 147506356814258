import { createSlice } from "@reduxjs/toolkit";

const fixedMarkupsSlice = createSlice({
  name: "fixedMarkups",
  initialState: {
    fixedMarkups: [],
  },
  reducers: {
    setFixedMarkups(state, action) {
      state.fixedMarkups = action.payload;
    },
  },
});

export const fixedMarkupsActions = fixedMarkupsSlice.actions;

export default fixedMarkupsSlice.reducer;
