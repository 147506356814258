import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useMountEffect from "../../../hooks/useMountEffect";

import { Box, CircularProgress, TextField, Button, MenuItem } from "@mui/material";

import { getFixedPrices, setFixedPrice } from "../../../store/actions/fixedPrices";
import { authActions } from "../../../store/slices/auth";

import classes from "./FixedPricesContent.module.css";

import FixedPricesTable from "./FixedPricesTable";

function FixedPricesContent() {
  const dispatch = useDispatch();
  const fixedPricesList = useSelector((state) => state.fixedPrices.fixedPrices);
  const forceReload = useSelector((state) => state.auth.forceReload);

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ partNumber: "", price: "", status: "", active: "" });

  const handleFormChange = (event) => {
    const target = event.target.name;
    let newForm = JSON.parse(JSON.stringify(form));
    if (typeof newForm[target] === "undefined") {
      return console.log(target + " not found!");
    }
    newForm[target] = event.target.value;

    setForm(newForm);
  };

  const loadData = async (force) => {
    if (force || (fixedPricesList && Object.keys(fixedPricesList).length <= 0)) {
      setIsLoading(true);
      await dispatch(getFixedPrices());
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  }, [forceReload]);

  useMountEffect(() => {
    loadData();
  });

  const handleSave = async () => {
    const saveResult = await dispatch(setFixedPrice(form));
    if (saveResult) {
      setForm({ partNumber: "", price: "", status: "", active: "" });
      loadData(true);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <h2>Ceny ustalone</h2>
      </div>
      <div className={classes.section}>
        <div className={classes.row}>
          <TextField
            id="fixed_prices_pn_input"
            className={classes.textField}
            label="PartNumber"
            type="text"
            name="partNumber"
            value={form.partNumber}
            size="small"
            onChange={handleFormChange}
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
          />
          <TextField
            id="fixed_prices_price_input"
            className={classes.textField}
            label="Cena"
            type="number"
            name="price"
            value={form.price}
            size="small"
            onChange={handleFormChange}
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
          />
          <TextField
            id="fixed_prices_status_select"
            className={classes.textField}
            label="Status"
            select
            value={form.status}
            name="status"
            onChange={handleFormChange}
            size="small"
            sx={{ width: "200px", marginTop: "3px" }}
          >
            <MenuItem value="">
              <em>Nie zmieniaj</em>
            </MenuItem>
            <MenuItem value="Nieaktywny">Nieaktywny</MenuItem>
            <MenuItem value="Brak">Brak</MenuItem>
            <MenuItem value="Na zamówienie">Na zamówienie</MenuItem>
            <MenuItem value="Dostępny">Dostępny</MenuItem>
          </TextField>
          <TextField
            id="fixed_prices_active_select"
            className={classes.textField}
            label="Aktywny"
            select
            value={form.active}
            name="active"
            onChange={handleFormChange}
            size="small"
            sx={{ width: "120px", marginTop: "3px" }}
          >
            <MenuItem value="">
              <em>Nie zmieniaj</em>
            </MenuItem>
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
          </TextField>
          <Button color="success" onClick={handleSave}>
            Zapisz
          </Button>
        </div>
      </div>
      <div className={classes.section}>{fixedPricesList && <FixedPricesTable fixedPricesList={fixedPricesList} />}</div>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", zIndex: 100 }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default FixedPricesContent;
