import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Stack,
  Box,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Checkbox,
  Button,
  Pagination,
} from "@mui/material";

import { getInsertList, removeFromInsertList } from "../../store/actions/insertList";
import { authActions } from "../../store/slices/auth";
import classes from "./InsertListContent.module.css";

function InsertTab({ manufacturer }) {
  const dispatch = useDispatch();
  const insertList = useSelector((state) => state.insertList[manufacturer].docs);
  const totalPages = useSelector((state) => state.insertList[manufacturer].totalPages);
  const perPage = useSelector((state) => state.insertList[manufacturer].limit);
  const forceReload = useSelector((state) => state.auth.forceReload);

  const [blacklistArray, setBlacklistArray] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const loadData = async (force, page) => {
    if (force || (insertList && Object.keys(insertList).length <= 0)) {
      setIsLoading(true);
      await dispatch(getInsertList(manufacturer, page ? page : currentPage));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    loadData(true, 1);
  }, [manufacturer]);

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  }, [forceReload]);

  const handleClick = (event, row) => {
    let newArray = [...blacklistArray];
    if (event.target.checked) {
      newArray.push(row.partNumber);
    } else {
      const index = newArray.indexOf(row.partNumber);
      if (index > -1) {
        newArray.splice(index, 1);
      }
    }
    setBlacklistArray(newArray);
  };

  const handleSave = async () => {
    setIsLoading(true);
    await dispatch(removeFromInsertList(manufacturer, blacklistArray));
    setBlacklistArray([]);
    loadData(true);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    setBlacklistArray([]);
    loadData(true, page);
  };

  const handleCheckAll = () => {
    if (insertList?.length === blacklistArray?.length) {
      setBlacklistArray([]);
    } else {
      setBlacklistArray(insertList.map((row) => row.partNumber));
    }
  };

  return (
    <div className={classes.container}>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", zIndex: 100 }}>
          <CircularProgress />
        </Box>
      )}
      <div className={classes.row}>
        <h2>Do dodania {manufacturer}</h2>
        <Stack direction="row" gap={1}>
          <Button variant="outlined" color="info" onClick={handleCheckAll}>
            {insertList?.length === blacklistArray?.length ? "Odznacz" : "Zaznacz"}
          </Button>
          <Button variant="outlined" color="success" disabled={blacklistArray.length <= 0} onClick={handleSave}>
            Zapisz
          </Button>
        </Stack>
      </div>
      {insertList && !isLoading && (
        <TableContainer component={Paper} sx={{ padding: "0 10px 20px" }} className={classes.table}>
          <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ "& th": { padding: "5px 20px" } }}>
                <TableCell align="right">LP</TableCell>
                <TableCell align="left">Producent</TableCell>
                <TableCell align="left">PN</TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>TD</div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>AB</div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>Ingram</div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>Also</div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>Eet</div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.headerCell}>Action</div>
                </TableCell>
                <TableCell align="center">Usuń</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {insertList.map((row, index) => (
                <TableRow key={row.partNumber} sx={{ "& td, & th": { fontSize: "12px", padding: "5px 20px" } }}>
                  <TableCell scope="row" align="right">
                    {perPage * (currentPage - 1) + index + 1}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    sx={{ width: "120px", textTransform: "capitalize" }}
                  >
                    {row.manufacturer}
                  </TableCell>
                  <TableCell scope="row" align="left" width="100%">
                    {row.partNumber}
                  </TableCell>
                  <TableCell align="right" sx={row.tdStock > 0 ? "font-weight: bold" : ""} className={classes.tdBorder}>
                    {row.tdStock}
                  </TableCell>
                  <TableCell align="right" sx={row.abStock > 0 ? "font-weight: bold" : ""} className={classes.tdBorder}>
                    {row.abStock}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={row.ingramStock > 0 ? "font-weight: bold" : ""}
                    className={classes.tdBorder}
                  >
                    {row.ingramStock}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={row.alsoStock > 0 ? "font-weight: bold" : ""}
                    className={classes.tdBorder}
                  >
                    {row.alsoStock}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={row.eetStock > 0 ? "font-weight: bold" : ""}
                    className={classes.tdBorder}
                  >
                    {row.eetStock}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={row.actionStock > 0 ? "font-weight: bold" : ""}
                    className={classes.tdBorder}
                  >
                    {row.actionStock}
                  </TableCell>
                  <TableCell align="center" className={classes.checkColumn}>
                    <Checkbox
                      size="small"
                      checked={blacklistArray.some((bl) => row.partNumber === bl)}
                      onClick={(event) => handleClick(event, row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className={classes.paginationRow}>
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
      </div>
    </div>
  );
}

export default InsertTab;
