import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useMountEffect from "../../../hooks/useMountEffect";

import { Box, CircularProgress, TextField, Button, MenuItem, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { getDisabledProducts, setDisabledProduct } from "../../../store/actions/disabledProducts";
import { authActions } from "../../../store/slices/auth";

import config from "../../../config";
import classes from "./DisabledProductsContent.module.css";

import DisabledProductsTable from "./DisabledProductsTable";

const { distributorsDict } = config;

function DisabledProductsContent() {
  const dispatch = useDispatch();
  const disabledProductsList = useSelector((state) => state.disabledProducts.disabledProducts);
  const forceReload = useSelector((state) => state.auth.forceReload);

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ partNumber: "", distributor: "", isDisabled: true });
  const [searchInput, setSearchInput] = useState("");

  const handleSetSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleFormChange = (event) => {
    const target = event.target.name;
    let newForm = JSON.parse(JSON.stringify(form));
    if (typeof newForm[target] === "undefined") {
      return console.log(target + " not found!");
    }
    newForm[target] = event.target.value;

    setForm(newForm);
  };

  const loadData = async (force) => {
    if (force || (disabledProductsList && Object.keys(disabledProductsList).length <= 0)) {
      setIsLoading(true);
      await dispatch(getDisabledProducts());
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  }, [forceReload]);

  useMountEffect(() => {
    loadData();
  });

  const handleSave = async () => {
    const saveResult = await dispatch(setDisabledProduct(form));
    if (saveResult) {
      setForm({ partNumber: "", distributor: "", isDisabled: true });
      loadData(true);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <h2>Blokowanie produktów</h2>
      </div>
      <div className={classes.rowSection}>
        <div className={classes.row}>
          <TextField
            id="disabled_products_pn_input"
            className={classes.textField}
            label="PartNumber"
            type="text"
            name="partNumber"
            value={form.partNumber}
            size="small"
            onChange={handleFormChange}
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
          />
          <TextField
            id="disabled_products_distributor_select"
            className={classes.textField}
            label="Dystrybucja"
            select
            value={form.distributor}
            name="distributor"
            onChange={handleFormChange}
            size="small"
            sx={{ width: "200px", marginTop: "3px" }}
          >
            {distributorsDict.map((manu) => {
              return (
                <MenuItem value={manu.value} key={manu.value}>
                  {manu.label}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            id="disabled_products_status_select"
            className={classes.textField}
            label="Status"
            select
            value={form.isDisabled}
            name="isDisabled"
            onChange={handleFormChange}
            size="small"
            sx={{ width: "200px", marginTop: "3px" }}
          >
            <MenuItem value={true}>Zablokuj</MenuItem>
            <MenuItem value={false}>Odblokuj</MenuItem>
          </TextField>
          <Button color="success" onClick={handleSave}>
            Zapisz
          </Button>
        </div>
        <div>
          <TextField
            className={classes.searchInput}
            id="disabled-products-search-input"
            label="Szukaj PN"
            value={searchInput}
            onChange={handleSetSearchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            type="search"
            size="small"
          />
        </div>
      </div>
      <div className={classes.section}>
        {disabledProductsList && (
          <DisabledProductsTable disabledProductsList={disabledProductsList} searchInput={searchInput} />
        )}
      </div>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", zIndex: 100 }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default DisabledProductsContent;
