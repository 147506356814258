import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useMountEffect from "../../../hooks/useMountEffect";

import { Box, CircularProgress, TextField, Button, MenuItem, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { getFixedMarkups, setFixedMarkup } from "../../../store/actions/fixedMarkups";
import { authActions } from "../../../store/slices/auth";

import classes from "./FixedMarkupsContent.module.css";

import FixedMarkupsTable from "./FixedMarkupsTable";

function FixedMarkupContent() {
  const dispatch = useDispatch();
  const fixedMarkupsList = useSelector((state) => state.fixedMarkups.fixedMarkups);
  const forceReload = useSelector((state) => state.auth.forceReload);

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ partNumber: "", price: 0, percent: 0 });
  const [searchInput, setSearchInput] = useState("");

  const handleSetSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleFormChange = (event) => {
    const target = event.target.name;
    let newForm = JSON.parse(JSON.stringify(form));
    if (typeof newForm[target] === "undefined") {
      return console.log(target + " not found!");
    }
    newForm[target] = event.target.value;

    setForm(newForm);
  };

  const loadData = async (force) => {
    if (force || (fixedMarkupsList && Object.keys(fixedMarkupsList).length <= 0)) {
      setIsLoading(true);
      await dispatch(getFixedMarkups());
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  }, [forceReload]);

  useMountEffect(() => {
    loadData();
  });

  const handleSave = async () => {
    const saveResult = await dispatch(setFixedMarkup(form));
    if (saveResult) {
      setForm({ partNumber: "", price: 0, percent: 0 });
      loadData(true);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h2>Marże ustalone</h2>
      </div>
      <div className={classes.rowSection}>
        <div className={classes.row}>
          <TextField
            id="fixed_markups_pn_input"
            className={classes.textField}
            label="PartNumber"
            type="text"
            name="partNumber"
            value={form.partNumber}
            size="small"
            onChange={handleFormChange}
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
          />
          <TextField
            id="fixed_markups_price_input"
            className={classes.textField}
            label="Kwota"
            type="number"
            name="price"
            value={form.price}
            size="small"
            onChange={handleFormChange}
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
          />
          <TextField
            id="fixed_markups_percent_input"
            className={classes.textField}
            label="Procent"
            type="number"
            inputProps={{ step: 0.01 }}
            name="percent"
            value={form.percent}
            size="small"
            onChange={handleFormChange}
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
          />
          <Button color="success" onClick={handleSave}>
            Zapisz
          </Button>
        </div>
        <div>
          <TextField
            className={classes.searchInput}
            id="fixed-markup-search-input"
            label="Szukaj PN"
            value={searchInput}
            onChange={handleSetSearchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            type="search"
            size="small"
          />
        </div>
      </div>
      <div className={classes.section}>{fixedMarkupsList && <FixedMarkupsTable fixedMarkupsList={fixedMarkupsList} searchInput={searchInput} />}</div>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", zIndex: 100 }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}

export default FixedMarkupContent;
