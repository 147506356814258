import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useMountEffect from "../../../hooks/useMountEffect";

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Switch, Button, Box, CircularProgress } from "@mui/material";

import { getDistributors, setDistributor } from "../../../store/actions/distributors";
import { authActions } from "../../../store/slices/auth";

import classes from "./DistributorTable.module.css";
import { useEffect } from "react";

function DistributorTable(props) {
  const dispatch = useDispatch();
  const distributorsList = useSelector((state) => state.distributors.distributors);
  const forceReload = useSelector((state) => state.auth.forceReload);

  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [form, setForm] = useState([]);

  const loadData = async (force) => {
    if (force || (distributorsList && Object.keys(distributorsList).length <= 0)) {
      setIsLoading(true);
      await dispatch(getDistributors());
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  }, [forceReload]);

  useMountEffect(() => {
    loadData();
  });

  const handleEditChange = () => {
    setIsEdit((prev) => {
      if (prev) {
        setForm(distributorsList);
      }
      return !prev;
    });
  };

  useEffect(() => {
    setForm(distributorsList);
  }, [distributorsList]);

  const handleFormChange = (event) => {
    const target = event.target.name;
    let newForm = JSON.parse(JSON.stringify(form));
    const foundIndex = newForm.findIndex((el) => el.name === target);
    if (foundIndex == -1) {
      return console.log(target + " not found!");
    }

    newForm[foundIndex].isActive = event.target.checked;

    setForm(newForm);
  };

  const handleSave = async () => {
    const saveResult = await dispatch(setDistributor(form));
    if (saveResult) {
      loadData(true);
      setIsEdit(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerRow}>
        <h2>Dystrybucje</h2>
        <div className={classes.buttonColumn}>
          {isEdit && (
            <Button className={classes.saveButton} onClick={handleSave} color="success">
              Zapisz
            </Button>
          )}
          <Button onClick={handleEditChange} variant="outlined">
            {isEdit ? "Anuluj" : "Edytuj"}
          </Button>
        </div>
      </div>
      {form && (
        <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
          <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ "& th": { padding: "6px 6px" } }}>
                <TableCell sx={{ width: "80px" }} align="center">
                  LP
                </TableCell>
                <TableCell align="left">Dytrybucja</TableCell>
                <TableCell align="right">Aktywność</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {form.map((row, index) => (
                <TableRow key={row.name} sx={{ "& td, & th": { fontSize: "12px", padding: "3px 6px" } }}>
                  <TableCell scope="row" align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell scope="row" align="right">
                    <Switch disabled={!isEdit} checked={row.isActive} name={row.name} onChange={handleFormChange} color="success" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", zIndex: 100 }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}

export default DistributorTable;
