import config from "../../config";

import { logout } from "./auth";
import { uiActions } from "../slices/ui";

export const sendRequest =
  (endpoint, body = {}, method = "POST", type, linkRef) =>
  async (dispatch) => {
    let result = true;

    const requestMethod = async () => {
      const token = localStorage.getItem("token");

      let headers = {};

      if (type === "upload") {
        // headers["Content-Type"] = "multipart/form-data";
      } else if (type !== "file") {
        headers["Content-Type"] = "application/json";
      }

      if (token) {
        headers["Authorization"] = "Bearer " + token;
      }

      const fetchParams = {
        method: method,
        headers: headers,
      };

      let queryParams = "";

      if (method === "GET") {
        if (typeof body !== "object") {
          queryParams = `/${body}`;
        }
      } else {
        if (type === "upload") {
          fetchParams["body"] = body;
        } else {
          fetchParams["body"] = JSON.stringify(body);
        }
      }

      const response = await fetch(`${config.apiUrl}${endpoint}${queryParams}`, fetchParams);

      if (!response.ok) {
        console.log(response);
        const error = new Error("Request error!");
        error.code = response.status;
        if (response.status === 401) {
          dispatch(
            uiActions.showNotification({
              status: "warning",
              title: "Warning!",
              message: "Sesja zakończona",
            })
          );
          dispatch(logout());
        } else {
          throw error;
        }
      }

      let data;
      if (type !== "file") {
        data = await response.json();
      } else {
        data = await response.blob();
        const href = window.URL.createObjectURL(data);
        const a = linkRef.current;
        const fileName = method === "GET" && typeof body !== "object" ? body + ".csv" : "result.csv";
        a.download = fileName;
        a.href = href;
        a.click();
        a.href = "";

        return data;
      }

      return data;
    };

    result = await requestMethod();
    return Promise.resolve(result);
  };
