import { uiActions } from "../slices/ui";
import { fixedMarkupsActions } from "../slices/fixedMarkups";

import { sendRequest } from "./request";

export const getFixedMarkups = () => async (dispatch) => {
  let result = [];
  try {
    result = await dispatch(sendRequest("fixed-markups", {}, "GET"));

    dispatch(fixedMarkupsActions.setFixedMarkups(result));
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve(result);
};

export const setFixedMarkup = (fixedMarkup) => async (dispatch) => {
  let result = false;
  try {
    await dispatch(
      sendRequest("fixed-markups", { ...fixedMarkup, price: +fixedMarkup.price, percent: +fixedMarkup.percent })
    );

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Success!",
        message: "Stawki zaktualizowane",
      })
    );
    result = true;
  } catch (err) {
    console.log(err);
    if (err.code === 409) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Error!",
          message: "Istniejący klucz!",
        })
      );
    } else {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Błąd zapisu!",
        })
      );
    }
  }
  return Promise.resolve(result);
};

export const deleteFixedMarkup = (fixedMarkup) => async (dispatch) => {
  let result = false;
  try {
    await dispatch(sendRequest(`fixed-markups/${fixedMarkup._id}`, {}, "DELETE"));

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Success!",
        message: "Cena usunięta",
      })
    );
    result = true;
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd zapisu!",
      })
    );
  }
  return Promise.resolve(result);
};
