import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    isLoggedIn: false,
    user: null,
    forceReload: false,
  },
  reducers: {
    setToken(state, action) {
      if (action.payload) {
        state.isLoggedIn = true;
      } else {
        state.isLoggedIn = false;
      }
      state.token = action.payload;
    },
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setForceReload(state, action) {
      state.forceReload = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
