import { createSlice } from "@reduxjs/toolkit";

const accessoriesSlice = createSlice({
  name: "accessories",
  initialState: {
    accessories: [],
  },
  reducers: {
    setAccessories(state, action) {
      state.accessories = action.payload;
    },
  },
});

export const accessoriesActions = accessoriesSlice.actions;

export default accessoriesSlice.reducer;
