import { createSlice } from "@reduxjs/toolkit";

const distributorsSlice = createSlice({
  name: "distributors",
  initialState: {
    distributors: [],
  },
  reducers: {
    setDistributors(state, action) {
      state.distributors = action.payload;
    },
  },
});

export const distributorsActions = distributorsSlice.actions;

export default distributorsSlice.reducer;
