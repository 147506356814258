import { Box, Modal, TextField, Button, Typography } from "@mui/material";

import classes from "./WantedProductsModal.module.css";

import { useDispatch } from "react-redux";

import { getWantedProducts, setWantedProduct, deleteWantedProduct } from "../../store/actions/wantedProducts";

const WantedProductsModal = (params) => {
  const dispatch = useDispatch();
  const handleClose = () => params.setIsModalOpen(false);

  const handleAccept = async (e) => {
    let saveResult;
    saveResult = await dispatch(deleteWantedProduct(params.wantedProduct));
    if (saveResult) {
      await dispatch(getWantedProducts());
      params.setIsModalOpen(false);
    }
  };

  return (
    <div>
      <Modal
        open={params.isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.box}>
          <Typography id="wanted-product-modal-title" variant="h6" component="h2" sx={{ marginTop: "10px" }}>
            {params.wantedProduct?.partNumber}
          </Typography>
          <div className={classes.inputSection}>
            <p>Czy chcesz usunąć ten produkt z poszukiwanych?</p>
          </div>
          <div className={classes.centerRow}>
            <Button color="warning" onClick={handleClose} sx={{ marginRight: "5px" }}>
              Anuluj
            </Button>
            <Button color="success" variant="outlined" onClick={handleAccept} sx={{ marginLeft: "5px" }}>
              Akceptuj
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default WantedProductsModal;
