import { uiActions } from "../slices/ui";
import { authActions } from "../slices/auth";

import { sendRequest } from "./request";

export const login = (credentials) => async (dispatch) => {
  try {
    const loginResult = await dispatch(sendRequest("auth/login", credentials));

    const expirationTime = new Date(new Date().getTime() + 3600 * 1000);

    dispatch(authActions.setToken(loginResult.access_token));
    localStorage.setItem("token", loginResult.access_token);
    localStorage.setItem("expirationTime", expirationTime);
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Logowanie nieudane!",
      })
    );
  }
  return Promise.resolve();
};

export const logout = () => async (dispatch) => {
  dispatch(authActions.setToken(null));
  localStorage.removeItem("token");
  localStorage.removeItem("expirationTime");
  return;
};

export const retrieveToken = () => async (dispatch) => {
  const storedToken = localStorage.getItem("token");
  const storedExpirationDate = localStorage.getItem("expirationTime");

  if (storedToken) {
    const remainingTime = calculateRemainingTime(storedExpirationDate);

    if (remainingTime <= 60000) {
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
    } else {
      dispatch(authActions.setToken(storedToken));
      localStorage.setItem("token", storedToken);
      localStorage.setItem("expirationTime", storedExpirationDate);
    }
  }
  return;
};

export const getUser = () => async (dispatch) => {
  try {
    const result = await dispatch(sendRequest("auth/user", {}, "GET"));

    dispatch(authActions.setUser(result));
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve();
};

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};
