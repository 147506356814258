import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useMountEffect from "../../hooks/useMountEffect";

import { Box, CircularProgress, TextField, Button } from "@mui/material";

import classes from "./MarkupPageContent.module.css";

import { getMarkups, setMarkup } from "../../store/actions/markups";
import { authActions } from "../../store/slices/auth";

const markups = [
  { label: "Marża (<2000)", name: "markup" },
  { label: "Marża TD", name: "td_markup" },
  { label: "Marża AB", name: "ab_markup" },
  { label: "Marża INGRAM", name: "ingram_markup" },
  { label: "Marża ALSO", name: "also_markup" },
  { label: "Marża EET", name: "eet_markup" },
  { label: "Marża ACTION", name: "action_markup" },
];

const percent_markups = [
  { label: "Marża % (<2000)", name: "percent_markup" },
  { label: "Marża % TD", name: "percent_td_markup" },
  { label: "Marża % AB", name: "percent_ab_markup" },
  { label: "Marża % INGRAM", name: "percent_ingram_markup" },
  { label: "Marża % ALSO", name: "percent_also_markup" },
  { label: "Marża % EET", name: "percent_eet_markup" },
  { label: "Marża % ACTION", name: "percent_action_markup" },
];

function MarkupPageContent() {
  const dispatch = useDispatch();
  const markupsList = useSelector((state) => state.markups.markups);
  const forceReload = useSelector((state) => state.auth.forceReload);

  const [isLoading, setIsLoading] = useState(false);
  const [formDell, setFormDell] = useState([
    { name: "euro", value: 4.65, manufacturer: "dell" },
    { name: "markup", value: "", manufacturer: "dell" },
    { name: "td_markup", value: "", manufacturer: "dell" },
    { name: "ab_markup", value: "", manufacturer: "dell" },
    { name: "ingram_markup", value: "", manufacturer: "dell" },
    { name: "also_markup", value: "", manufacturer: "dell" },
    { name: "eet_markup", value: "", manufacturer: "dell" },
    { name: "action_markup", value: "", manufacturer: "dell" },
  ]);
  const [formPercentDell, setFormPercentDell] = useState([
    { name: "percent_markup", value: "", manufacturer: "dell" },
    { name: "percent_td_markup", value: "", manufacturer: "dell" },
    { name: "percent_ab_markup", value: "", manufacturer: "dell" },
    { name: "percent_ingram_markup", value: "", manufacturer: "dell" },
    { name: "percent_also_markup", value: "", manufacturer: "dell" },
    { name: "percent_eet_markup", value: "", manufacturer: "dell" },
    { name: "percent_action_markup", value: "", manufacturer: "dell" },
  ]);
  const [formLenovo, setFormLenovo] = useState([
    { name: "euro", value: 4.65, manufacturer: "lenovo" },
    { name: "markup", value: "", manufacturer: "lenovo" },
    { name: "td_markup", value: "", manufacturer: "lenovo" },
    { name: "ab_markup", value: "", manufacturer: "lenovo" },
    { name: "ingram_markup", value: "", manufacturer: "lenovo" },
    { name: "also_markup", value: "", manufacturer: "lenovo" },
    { name: "eet_markup", value: "", manufacturer: "lenovo" },
    { name: "action_markup", value: "", manufacturer: "lenovo" },
  ]);
  const [formPercentLenovo, setFormPercentLenovo] = useState([
    { name: "percent_markup", value: "", manufacturer: "lenovo" },
    { name: "percent_td_markup", value: "", manufacturer: "lenovo" },
    { name: "percent_ab_markup", value: "", manufacturer: "lenovo" },
    { name: "percent_ingram_markup", value: "", manufacturer: "lenovo" },
    { name: "percent_also_markup", value: "", manufacturer: "lenovo" },
    { name: "percent_eet_markup", value: "", manufacturer: "lenovo" },
    { name: "percent_action_markup", value: "", manufacturer: "lenovo" },
  ]);

  const loadData = async (force) => {
    if (force || (markupsList && Object.keys(markupsList).length <= 0)) {
      setIsLoading(true);
      const markupResult = await dispatch(getMarkups());
      if (markupResult.length > 0) {
        setFormDell(markupResult.filter((x) => x.manufacturer === "dell" && !x.name.includes("percent")));
        setFormLenovo(markupResult.filter((x) => x.manufacturer === "lenovo" && !x.name.includes("percent")));
        if (markupResult.filter((x) => x.manufacturer === "dell" && x.name.includes("percent")).length > 0) {
          setFormPercentDell(markupResult.filter((x) => x.manufacturer === "dell" && x.name.includes("percent")));
        }
        if (markupResult.filter((x) => x.manufacturer === "lenovo" && x.name.includes("percent")).length > 0) {
          setFormPercentLenovo(markupResult.filter((x) => x.manufacturer === "lenovo" && x.name.includes("percent")));
        }
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  }, [forceReload]);

  useMountEffect(() => {
    loadData(true);
  });

  const handleFormChange = (event, form, setForm) => {
    const target = event.target.name;
    let newForm;
    newForm = JSON.parse(JSON.stringify(form));
    let arrIndex = newForm.findIndex((x) => x.name === target);
    if (arrIndex === -1) {
      if (form.length > 0) {
        newForm.push({ manufacturer: newForm[0].manufacturer, name: target, value: event.target.value });
      } else {
        return console.log(target + " not found!");
      }
    } else {
      newForm[arrIndex].value = event.target.value;
    }

    setForm(newForm);
  };

  const handleSave = async () => {
    const form = [...formDell, ...formLenovo, ...formPercentDell, ...formPercentLenovo];
    await dispatch(setMarkup(form));
    loadData(true);
  };

  return (
    <>
      <div className={classes.header}>
        <h2>Stawki</h2>
      </div>
      <div className={classes.container}>
        <div className={classes.container}>
          {formDell.length > 0 && (
            <div className={classes.box}>
              <div className={classes.boxHeader}>Dell</div>
              <TextField
                id="euro"
                label="Kurs Euro"
                type="number"
                name="euro"
                value={formDell.find((x) => x.name === "euro")?.value || ""}
                size="small"
                inputProps={{ step: 0.01 }}
                onChange={(event) => handleFormChange(event, formDell, setFormDell)}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="dense"
              />
              <div className={classes.row}>
                <div className={classes.column}>
                  {markups.map((markup) => {
                    return (
                      <TextField
                        sx={{ marginRight: "5px", width: "150px" }}
                        key={markup.name}
                        id={markup.name}
                        label={markup.label}
                        type="number"
                        name={markup.name}
                        value={formDell.find((x) => x.name === markup.name)?.value || ""}
                        size="small"
                        onChange={(event) => handleFormChange(event, formDell, setFormDell)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="dense"
                      />
                    );
                  })}
                </div>
                <div className={classes.column}>
                  {percent_markups.map((markup) => {
                    return (
                      <TextField
                        sx={{ marginLeft: "5px", width: "150px" }}
                        key={markup.name}
                        id={markup.name}
                        label={markup.label}
                        type="number"
                        name={markup.name}
                        inputProps={{ step: 0.01 }}
                        value={formPercentDell.find((x) => x.name === markup.name)?.value || ""}
                        size="small"
                        onChange={(event) => handleFormChange(event, formPercentDell, setFormPercentDell)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="dense"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={classes.container}>
          {formLenovo.length > 0 && (
            <div className={classes.box}>
              <div className={classes.boxHeader}>Lenovo</div>
              <TextField
                id="euro"
                label="Kurs Euro"
                type="number"
                name="euro"
                value={formLenovo.find((x) => x.name === "euro")?.value || ""}
                size="small"
                inputProps={{ step: 0.01 }}
                onChange={(event) => handleFormChange(event, formLenovo, setFormLenovo)}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="dense"
              />
              <div className={classes.row}>
                <div className={classes.column}>
                  {markups.map((markup) => {
                    return (
                      <TextField
                        sx={{ marginRight: "5px", width: "150px" }}
                        key={markup.name}
                        id={markup.name}
                        label={markup.label}
                        type="number"
                        name={markup.name}
                        value={formLenovo.find((x) => x.name === markup.name)?.value || ""}
                        size="small"
                        onChange={(event) => handleFormChange(event, formLenovo, setFormLenovo)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="dense"
                      />
                    );
                  })}
                </div>
                <div className={classes.column}>
                  {percent_markups.map((markup) => {
                    return (
                      <TextField
                        sx={{ marginLeft: "5px", width: "150px" }}
                        key={markup.name}
                        id={markup.name}
                        label={markup.label}
                        type="number"
                        name={markup.name}
                        inputProps={{ step: 0.01 }}
                        value={formPercentLenovo.find((x) => x.name === markup.name)?.value || ""}
                        size="small"
                        onChange={(event) => handleFormChange(event, formPercentLenovo, setFormPercentLenovo)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="dense"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={classes.container}>
        <Button className={classes.submitButton} color="success" onClick={handleSave}>
          Zapisz
        </Button>
      </div>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", zIndex: 100 }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default MarkupPageContent;
