import { uiActions } from "../slices/ui";
import { disabledProductsActions } from "../slices/disabledProducts";

import { sendRequest } from "./request";

export const getDisabledProducts = () => async (dispatch) => {
  let result = [];
  try {
    result = await dispatch(sendRequest("disabled-products", {}, "GET"));

    dispatch(disabledProductsActions.setDisabledProducts(result));
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd wczytywania danych!",
      })
    );
  }
  return Promise.resolve(result);
};

export const setDisabledProduct = (disabledProduct) => async (dispatch) => {
  let result = false;
  try {
    await dispatch(sendRequest("disabled-products", disabledProduct));

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Success!",
        message: "Rekord dodany",
      })
    );
    result = true;
  } catch (err) {
    console.log(err);
    if (err.code === 409) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Error!",
          message: "Istniejący klucz!",
        })
      );
    } else {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Błąd zapisu!",
        })
      );
    }
  }
  return Promise.resolve(result);
};

export const deleteDisabledProduct = (disabledProduct) => async (dispatch) => {
  let result = false;
  try {
    await dispatch(sendRequest(`disabled-products/${disabledProduct._id}`, {}, "DELETE"));

    dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Success!",
        message: "Rekord usunięty",
      })
    );
    result = true;
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Error!",
        message: "Błąd zapisu!",
      })
    );
  }
  return Promise.resolve(result);
};
