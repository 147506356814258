import InsertListContent from "../components/InsertList/InsertListContent";
import useQuery from "../hooks/useQuery";

const InsertList = () => {
  const query = useQuery();

  return <InsertListContent manufacturer={query.get("manufacturer")} />;
};

export default InsertList;
